<template>
  <mo-navigation-drawer v-model="drawer">
    <div slot="content">
      <div class="drawer-header">
        <div class="drawer-header-title" @click="clickLogo">UI Dev</div>
        <div>
          <mo-icon icon="setting" small @click="clickSettingIcon"></mo-icon>
        </div>
      </div>
      <div class="drawer-search">
        <div class="drawer-search-input">
          <mo-text-field v-model="searchValue" searchable></mo-text-field>
        </div>
      </div>
      <div class="drawer-menu">
        <div v-for="(menu, idx) in menus" :key="idx">
          <mo-section-menu :content="menu.name"></mo-section-menu>
          <mo-dropdown-menu
            v-for="menuLevel1 in menu.childItems"
            :key="menuLevel1.id"
            :title="menuLevel1.name"
            :height="getDropdownHeight(menuLevel1)"
          >
            <mo-menu-item
              v-for="menuLevel2 in menuLevel1.childItems"
              :key="menuLevel2.id"
              :content="menuLevel2.name"
              @click="move(menuLevel2.path)"
              :class="{ 'menu-active': isSelectedMenu(menuLevel2) }"
              depth="2"
            ></mo-menu-item>
          </mo-dropdown-menu>
        </div>
      </div>
    </div>
  </mo-navigation-drawer>
</template>

<script>
import SettingModal from './modal/SettingModal.vue';
import LogoBottomModal from './modal/LogoBottomModal.vue';

export default {
  name: 'SideDrawer',
  methods: {
    toggleSideDrawer() {
      this.drawer = !this.drawer;
    },
    getDropdownHeight(menu) {
      return menu.childItems.length * 48;
    },
    clickLogo() {
      this.toggleSideDrawer();
      this.$bottomModal.open(LogoBottomModal, {
        properties: {
          title: 'Language',
        },
        listeners: {
          closed: this.closeLogo,
        },
      });
    },
    closeLogo(param) {
      console.log(param);
    },
    clickSettingIcon() {
      this.toggleSideDrawer();
      this.$modal.open(SettingModal, {
        properties: {
          title: 'Settings',
        },
        listeners: {
          closed: this.closeSettingModal,
        },
      });
    },
    closeSettingModal(param) {
      console.log(param);
    },
    move(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      } else {
        this.toggleSideDrawer();
      }
    },
    isSelectedMenu(menu) {
      if (this.$route.path === menu.path) return true;
    },
  },
  data() {
    return {
      drawer: false,
      searchValue: null,
      menus: [
        {
          id: 1,
          name: 'Sample Pages',
          path: '',
          menuLevel: 1,
          childItems: [
            {
              id: 3,
              name: 'Samples',
              path: '',
              menuLevel: 2,
              childItems: [
                {
                  id: 31,
                  name: 'Sample1',
                  path: '/Samples/Sample1',
                  menuLevel: 3,
                },
                {
                  id: 32,
                  name: 'Sample2',
                  path: '/Samples/Sample2',
                  menuLevel: 3,
                },
                {
                  id: 33,
                  name: 'Sample3',
                  path: '/Samples/Sample3',
                  menuLevel: 3,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: 'Templates',
          path: '',
          menuLevel: 1,
          childItems: [
            {
              id: 7,
              name: 'Stack',
              path: '',
              menuLevel: 2,
              childItems: [
                {
                  id: 11,
                  name: 'Card',
                  path: '/Template/Stack/Card',
                  menuLevel: 3,
                },
                {
                  id: 12,
                  name: 'List',
                  path: '/Template/Stack/List',
                  menuLevel: 3,
                },
              ],
            },
            {
              id: 8,
              name: 'Complex',
              path: '',
              menuLevel: 2,
              childItems: [
                {
                  id: 13,
                  name: 'Main Home',
                  path: '/Template/Complex/MainHome',
                  menuLevel: 3,
                },
                {
                  id: 14,
                  name: 'Login Page',
                  path: '/Template/Complex/Login',
                  menuLevel: 3,
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style>
.drawer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #f7f7f7;
  padding: 20px 20px 20px 20px;
  align-items: center;
}

.drawer-header-title {
  font-size: 18px;
  font-weight: bold;
}

.drawer-search {
  display: flex;
  margin-top: 12px;
  justify-content: center;
}

.drawer-menu {
  margin-top: 12px;
}

.menu-active {
  background-color: #eaeaea;
}
</style>
