<template>
  <div>
    <mo-radio-wrapper v-model="value">
      <mo-list-item v-for="language in languages" :key="language.value">
        <div class="list-item__contents" @click="onClick(language.value)">
          <div class="list-item__contents__title">
            {{ language.name }}
          </div>
        </div>
        <div class="list-item__support">
          <mo-radio :value="language.value" />
        </div>
      </mo-list-item>
    </mo-radio-wrapper>
    <div class="modal_footer">
      <mo-button @click="closeModal">Cancel</mo-button>
      <mo-button @click="confirm" main>Okay</mo-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languages: [
        { name: '한국어', value: 'kor' },
        { name: 'English', value: 'eng' },
        { name: '日本語', value: 'jpn' },
        { name: '中國話', value: 'chn' },
      ],
      value: 'kor',
    };
  },
  methods: {
    onClick(v) {
      this.value = v;
    },
    closeModal() {
      this.$emit('closed', { result: false });
    },
    confirm() {
      this.$emit('closed', { result: true });
    },
  },
};
</script>

<style>
.modal_footer {
  display: flex;
  justify-content: flex-end;
}
</style>
